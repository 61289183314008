import { light, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import axios, { AxiosRequestConfig, RawAxiosRequestHeaders } from "axios";
import { UploadResponse } from "imagekit-javascript/dist/src/interfaces";
import { IKContext, IKImage, IKUpload } from "imagekitio-react";
import { camelCase } from "lodash";
import { FocusEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useSWR, { KeyedMutator } from "swr";

import Button from "../../../components/Button/Button";
import Checkbox from "../../../components/Checkbox/Checkbox";
import EmojiSelect from "../../../components/EmojiSelect/EmojiSelect";
import Input from "../../../components/Input/Input";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import SingleSelect from "../../../components/SingleSelect/SingleSelect";
import { Ban } from "../../../constants/enums/Ban";
import Document from "../../../constants/interfaces/Document";
import Emoji from "../../../constants/interfaces/Emoji";
import EmojiList from "../../../constants/interfaces/EmojiList";
import Version from "../../../constants/interfaces/Version";

const AdminEmoji = () => {
  const [checkedPowers, setCheckedPowers] = useState<string[]>([]);
  const [checkedRecommendations, setCheckedRecommendations] = useState<
    string[]
  >([]);
  const [emoji, setEmoji] = useState<Emoji>();
  const [firstTrigger, setFirstTrigger] = useState<boolean>(false);
  const [uploadStart, setUploadStart] = useState<boolean>();
  const { getToken } = useKindeAuth();
  const { id } = useParams();
  const { control, getValues, handleSubmit, register, setValue, watch } =
    useForm(
      id
        ? {
            defaultValues: async () => {
              return await axios
                .get(`${process.env.REACT_APP_API_ENDPOINT}/emojis/${id}`)
                .then((res) => {
                  const emoji = { ...res.data };

                  const transformMultiSelectValueToObject = (key: string) => {
                    res.data[key] = res.data[key]?.map((item: any) => ({
                      label: t(`${key}.${item}`),
                      value: item,
                    }));
                  };

                  multiSelectKeys.forEach((key) => {
                    transformMultiSelectValueToObject(key);
                  });

                  const transformLeveLNumbersToStrings = (
                    object: string,
                    key: string,
                  ) => {
                    if (res.data[object][key]) {
                      res.data[object][key] = res.data[object][key]?.map(
                        (level: number) => level.toString(),
                      );
                    }
                  };

                  Object.keys(res.data.powers).forEach((power) => {
                    transformLeveLNumbersToStrings("powers", power);
                  });

                  setCheckedPowers(Object.keys(res.data.powers));

                  if (res.data.recommendations) {
                    Object.keys(res.data.recommendations).forEach(
                      (recommendation) => {
                        transformLeveLNumbersToStrings(
                          "recommendations",
                          recommendation,
                        );
                      },
                    );

                    setCheckedRecommendations(
                      Object.keys(res.data.recommendations),
                    );
                  }

                  setFirstTrigger(!!res.data.triggers?.toFirstTrigger);
                  setEmoji(emoji);

                  if (res.data.position) {
                    const positionedAfter = emojis?.[res.data.position - 1];

                    res.data.position = {
                      label: t(`emojis.${positionedAfter?._id}`),
                      value: positionedAfter?._id,
                    };
                  }

                  return res.data;
                });
            },
          }
        : {},
    );
  const { t } = useTranslation();
  const bans = [Ban.FoodFight, Ban.Survival, Ban.Team];
  const headers: RawAxiosRequestHeaders = {
    "Content-Type": "application/json",
    "X-SimpleLocalize-Token": process.env.REACT_APP_SIMPLELOCALIZE_API_KEY,
  };
  const maxLevels = [1, 2, 3, 4, 5];
  const multiSelectKeys = ["blitzModifiers", "groups", "tags", "worlds"];
  const navigate = useNavigate();
  const triggers = [
    { key: "toFirstTrigger", value: "first" },
    { key: "toTrigger", value: "trigger" },
    { key: "toOvercharge", value: "overcharge" },
  ];

  const fetcher = (url: string, config?: AxiosRequestConfig) =>
    axios.get(url, config).then((res) => res.data);

  // Boxes
  const { data: boxes }: { data: Array<Document>; mutate: KeyedMutator<any> } =
    useSWR(`${process.env.REACT_APP_API_ENDPOINT}/boxes`, fetcher);

  // Blitz Modifiers
  const {
    data: blitzModifiers,
  }: { data: Array<Document>; mutate: KeyedMutator<any> } = useSWR(
    `${process.env.REACT_APP_API_ENDPOINT}/blitz-modifiers`,
    fetcher,
  );

  // Emojis
  const { data: emojis }: { data: EmojiList[]; mutate: KeyedMutator<any> } =
    useSWR(`${process.env.REACT_APP_API_ENDPOINT}/emojis/list`, fetcher);

  // Groups
  const { data: groups }: { data: Array<Document>; mutate: KeyedMutator<any> } =
    useSWR(`${process.env.REACT_APP_API_ENDPOINT}/groups`, fetcher);

  // Powers
  const { data: powers }: { data: Array<Document>; mutate: KeyedMutator<any> } =
    useSWR(`${process.env.REACT_APP_API_ENDPOINT}/powers`, fetcher);

  // Recommendations
  const {
    data: recommendations,
  }: { data: Array<Document>; mutate: KeyedMutator<any> } = useSWR(
    `${process.env.REACT_APP_API_ENDPOINT}/recommendations`,
    fetcher,
  );

  // Tags
  const { data: tags }: { data: Array<Document>; mutate: KeyedMutator<any> } =
    useSWR(`${process.env.REACT_APP_API_ENDPOINT}/tags`, fetcher);

  // Latest Version
  const { data: latestVersion }: { data: Version; mutate: KeyedMutator<any> } =
    useSWR(`${process.env.REACT_APP_API_ENDPOINT}/versions/latest`, fetcher);

  // Revision
  const { data: revision }: { data: Emoji; mutate: KeyedMutator<any> } = useSWR(
    `${process.env.REACT_APP_API_ENDPOINT}/emoji-revisions/${id}`,
    fetcher,
  );

  // Worlds
  const { data: worlds }: { data: Array<Document>; mutate: KeyedMutator<any> } =
    useSWR(`${process.env.REACT_APP_API_ENDPOINT}/worlds`, fetcher);

  // Languages
  const {
    data: languages,
  }: { data: { key: string; name: string }[]; mutate: KeyedMutator<any> } =
    useSWR("https://api.simplelocalize.io/api/v1/languages", (url) =>
      fetcher(url, {
        headers,
      }).then((res) => res.data),
    );

  // Translations
  const { data: translations }: { data: any; mutate: KeyedMutator<any> } =
    useSWR(
      `https://cdn.simplelocalize.io/${process.env.REACT_APP_SIMPLELOCALIZE_PROJECT_TOKEN}/_latest/_index`,
      fetcher,
    );

  const authenticator = async () => {
    return await axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/imagekit`)
      .then((res) => {
        const { expire, signature, token } = res.data;

        return { expire, signature, token };
      });
  };

  const deleteImage = async () => {
    const accessToken = await getToken();

    return await axios
      .delete(
        `${process.env.REACT_APP_API_ENDPOINT}/imagekit/${watch("fileId")}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      )
      .then(() => {
        setValue("fileId", "");
        setValue("filePath", "");
      });
  };

  const onSuccess = (res: UploadResponse) => {
    setValue("fileId", res.fileId);
    setValue("filePath", res.filePath);
    setUploadStart(false);
  };

  const banIcon = (ban: string) => {
    switch (ban) {
      case "foodFight":
        return light("soft-serve");
      case "survival":
        return light("bomb");
      case "team":
        return light("handshake");
      default:
        return light("square-question");
    }
  };

  const recommendationLabel = (
    recommendation: Document,
    size: string = "24",
  ) => {
    if (recommendation._id.startsWith("combo")) {
      const images = recommendation._id
        .slice(5)
        .split("Plus")
        .map((image) => image.charAt(0).toLocaleLowerCase() + image.slice(1));

      return (
        <span className="flex items-center">
          {t("recommendations.combo")}&nbsp;
          <IKImage
            alt={t(`recommendations.${images[0]}`)}
            className={size === "16" ? "h-4" : "h-6"}
            height={size}
            lqip={{ active: true }}
            path={`${images[0]}.png`}
            transformation={[
              {
                dpr: window.devicePixelRatio.toString(),
                width: size,
              },
            ]}
            urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT}
            width={size}
          />
          &nbsp;+&nbsp;
          {images[1] === "item" ? (
            t("recommendations.item")
          ) : (
            <IKImage
              alt={t(`recommendations.${images[1]}`)}
              className={size === "16" ? "h-4" : "h-6"}
              height={size}
              lqip={{ active: true }}
              path={`${images[1]}.png`}
              transformation={[
                {
                  dpr: window.devicePixelRatio.toString(),
                  width: size,
                },
              ]}
              urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT}
              width={size}
            />
          )}
        </span>
      );
    } else {
      return t(`recommendations.${recommendation._id}`);
    }
  };

  const averageTrigger = (trigger: string) => {
    const array: number[] = getValues(`triggers[${trigger}]`);
    const sum = array.reduce(
      (previousValue, currentValue) => +previousValue + +currentValue,
      0,
    );

    setValue(`${trigger}Average`, Math.round((sum / array.length) * 10) / 10);
  };

  const onSubmit = async (data: any) => {
    const accessToken = await getToken();
    const transformMultiSelectObjectToValue = (key: string) => {
      data[key] = data[key]?.map(
        (object: { label: string; value: string }) => object.value,
      );
    };

    multiSelectKeys.forEach((key) => {
      transformMultiSelectObjectToValue(key);
    });

    // Update English language translation if it is different from the published translation
    const formTranslationValue = getValues("translations.en");
    const publishedTranslationValue =
      translations["en"][`emojis.${id ?? getValues("_id")}`];

    const updateTranslations = () =>
      axios
        .patch(
          "https://api.simplelocalize.io/api/v2/translations",
          {
            key: `emojis.${id ?? camelCase(watch("_id"))}`,
            language: "en",
            text: formTranslationValue,
          },
          {
            headers,
          },
        )
        .then(() =>
          axios.post(
            "https://api.simplelocalize.io/api/v2/environments/_latest/publish",
            null,
            {
              headers,
            },
          ),
        );

    if (!publishedTranslationValue) {
      axios
        .post(
          "https://api.simplelocalize.io/api/v1/translation-keys",
          {
            key: `emojis.${id ?? camelCase(watch("_id"))}`,
          },
          {
            headers,
          },
        )
        .then(() => updateTranslations());
    } else if (formTranslationValue !== publishedTranslationValue) {
      updateTranslations();
    }

    const position =
      emojis?.findIndex((emoji) => emoji._id === watch("position")?.value) + 1;

    data.position = position ?? emojis.length;

    const updatePositions = () => {
      if (position !== emoji?.position) {
        const items = emojis?.map((emoji) => emoji._id);

        items.splice(position, 0, camelCase(getValues("_id")));

        axios.patch(
          `${process.env.REACT_APP_API_ENDPOINT}/emojis`,
          { items },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          },
        );
      }
    };

    if (id && id === camelCase(getValues("_id"))) {
      axios
        .put(`${process.env.REACT_APP_API_ENDPOINT}/emojis/${id}`, data, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(() => {
          updatePositions();
          navigate("/admin/emojis");
        });
    } else {
      data._id = camelCase(getValues("_id"));

      axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/emojis`, data, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(() => {
          updatePositions();
          navigate(`/admin/emojis#${data._id}`);
        });
    }
  };

  const onSaveVersion = async () => {
    const accessToken = await getToken();

    if (revision) {
      axios
        .put(
          `${process.env.REACT_APP_API_ENDPOINT}/emoji-revisions/${id}`,
          emoji,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          },
        )
        .then(() => {
          setValue("revision", +latestVersion.version);
          onSubmit(getValues());
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/emoji-revisions`, emoji, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(() => {
          setValue("revision", +latestVersion.version);
          onSubmit(getValues());
        });
    }
  };

  useEffect(() => {
    const foundEmoji = emojis?.find((emoji: EmojiList) => emoji._id === id);

    if (id && foundEmoji) {
      const positionedAfter = emojis?.[foundEmoji.position - 1];

      setValue("position", {
        label: t(`emojis.${positionedAfter?._id}`),
        value: positionedAfter?._id,
      });
    }
  }, [emojis, id, setValue, t]);

  return (
    <>
      <div className="mb-4 flex gap-2">
        <FontAwesomeIcon
          className="h-7 w-7 text-purple-500 dark:text-purple-400"
          icon={light("smile")}
        />

        <h1 className="text-xl font-bold text-slate-900 dark:text-slate-100">
          {id ? t("edit") : t("add")} {t("emoji")}
        </h1>
      </div>

      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        {/* Name */}
        <Input
          id="_id"
          label="admin.name"
          name="_id"
          register={register}
          required
        />

        <Input
          defaultValue={translations?.["en"][`emojis.${id ?? watch("_id")}`]}
          id="translations.en"
          label={languages?.find((language) => language.key === "en")?.name}
          name="translations.en"
          register={register}
          required
        />

        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex">
                <Button
                  label={`${open ? t("hide") : t("show")} ${t(
                    "admin.translations",
                  )}`}
                >
                  <FontAwesomeIcon
                    className="h-5 w-5"
                    icon={light("language")}
                  />
                  {open ? t("hide") : t("show")} {t("admin.translations")}
                </Button>
              </Disclosure.Button>
              <Disclosure.Panel className="flex flex-col gap-4">
                {languages
                  ?.filter((language) => language.key !== "en")
                  .map((language) => {
                    const publishedTranslationValue =
                      translations?.[language.key][
                        `emojis.${id ?? watch("_id")}`
                      ];

                    return (
                      <div className="flex items-end gap-2" key={language.key}>
                        <div className="grow">
                          <Input
                            defaultValue={publishedTranslationValue}
                            id={`translations.${language.key}`}
                            key={language.key}
                            label={language.name}
                            name={`translations.${language.key}`}
                            onBlur={(event: FocusEvent<HTMLInputElement>) => {
                              const text = event.currentTarget.value;

                              if (text !== publishedTranslationValue) {
                                axios
                                  .patch(
                                    "https://api.simplelocalize.io/api/v2/translations",
                                    {
                                      key: `emojis.${id ?? watch("_id")}`,
                                      language: language.key,
                                      text,
                                    },
                                    {
                                      headers,
                                    },
                                  )
                                  .then((res) => {
                                    if (res.status === 200) {
                                      setValue(
                                        `translations.${language.key}`,
                                        text,
                                      );
                                    }
                                  });
                              }
                            }}
                            register={register}
                          />
                        </div>

                        {(watch(`translations.${language.key}`) !==
                          publishedTranslationValue ||
                          !publishedTranslationValue) && (
                          <FontAwesomeIcon
                            className="mb-px h-5 w-5 py-2 text-yellow-500 dark:text-yellow-400"
                            icon={solid("triangle-exclamation")}
                          />
                        )}
                      </div>
                    );
                  })}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* Image */}
        <div className="flex gap-4">
          <div className="shrink-0">
            <IKContext
              authenticator={authenticator}
              publicKey={process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY}
              urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT}
            >
              <label
                className="text-slate-600 dark:text-slate-300"
                htmlFor="upload"
              >
                {t("admin.image")}{" "}
                <span className="text-sm text-slate-400">
                  ({t("optional")})
                </span>
              </label>
              {watch("filePath") ? (
                <div className="relative flex h-[116px] w-[116px] items-center justify-center rounded border border-slate-300 bg-slate-200 dark:border-slate-600 dark:bg-slate-800">
                  <IKImage
                    height="96"
                    loading="lazy"
                    lqip={{ active: true }}
                    path={watch("filePath")}
                    transformation={[
                      {
                        cropMode: "pad_resize",
                        dpr: window.devicePixelRatio.toString(),
                        height: "96",
                        width: "96",
                      },
                    ]}
                    width="96"
                  />
                  <button
                    aria-label={t("delete")}
                    className="absolute right-0 top-0 h-10 w-10 -translate-y-1/4 translate-x-1/4 rounded-full border border-transparent bg-purple-500 p-2 text-sm font-bold text-white transition-colors hover:bg-purple-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500/50 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-purple-500 dark:bg-purple-600 dark:text-slate-100 dark:hover:bg-purple-700 dark:focus-visible:ring-purple-600/50 disabled:dark:hover:bg-purple-600"
                    onClick={deleteImage}
                    type="button"
                  >
                    <FontAwesomeIcon
                      className="h-5 w-5"
                      icon={light("trash")}
                    />
                  </button>
                </div>
              ) : (
                <label className="flex h-[116px] w-[116px] cursor-pointer items-center justify-center rounded border-2 border-dashed border-slate-300 bg-slate-200 text-slate-400 transition-colors hover:border-slate-400 hover:bg-slate-300 hover:text-slate-500 dark:border-slate-600 dark:bg-slate-800 dark:text-slate-600 hover:dark:border-slate-500 hover:dark:bg-slate-700 hover:dark:text-slate-500">
                  {uploadStart ? (
                    <svg
                      className="h-6 w-6 animate-spin text-purple-500 dark:text-purple-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        className="opacity-50"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  ) : (
                    <>
                      <FontAwesomeIcon
                        className="h-7 w-7"
                        icon={light("file-image")}
                      />
                      <IKUpload
                        className="hidden"
                        folder="/emojis/"
                        id="upload"
                        onSuccess={onSuccess}
                        onUploadStart={() => setUploadStart(true)}
                      />
                    </>
                  )}
                </label>
              )}
            </IKContext>
            <input {...register("fileId")} type="hidden"></input>
            <input
              {...register("filePath")}
              className="sr-only"
              onFocus={() => document.getElementById("upload")?.click()}
            ></input>
          </div>

          <div className="flex grow flex-col gap-4">
            <div className="flex gap-4">
              {/* Box */}
              <SingleSelect
                className="grow"
                control={control}
                inputId="boxes"
                label="boxes.box"
                name="box"
                onChange={(event: any) => {
                  setValue("box", event.value);
                  setValue("maxLevel", event.value === "silver" ? 3 : 5);
                }}
                options={boxes?.map((box) => ({
                  label: t(`boxes.${box._id}`),
                  value: box._id,
                }))}
                required
              />
            </div>

            {/* Max. Level */}
            <SingleSelect
              control={control}
              inputId="maxLevel"
              label="admin.maxLevel"
              name="maxLevel"
              onChange={(event: any) => {
                setValue("maxLevel", event.value);
              }}
              options={(watch("box") === "silver"
                ? maxLevels.filter((maxLevel) => maxLevel < 4)
                : maxLevels
              )?.map((maxLevel) => ({
                label: maxLevel,
                value: maxLevel,
              }))}
              required
            />
          </div>
        </div>

        {/* Exclusive */}
        <div className="flex items-center gap-3 pl-1">
          <FontAwesomeIcon
            className="h-5 w-5 text-purple-500 dark:text-purple-400"
            fixedWidth
            icon={light("gem")}
          />
          <Checkbox
            defaultChecked
            id="exclusive"
            label="exclusivity.exclusive"
            name="exclusive"
            register={register}
          />
        </div>

        {/* Group Collection Reward */}
        <div className="flex items-center gap-3 pl-1">
          <FontAwesomeIcon
            className="h-5 w-5 text-purple-500 dark:text-purple-400"
            fixedWidth
            icon={light("smile")}
          />
          <Checkbox
            id="groupCollectionReward"
            label="exclusivity.groupCollectionReward"
            name="groupCollectionReward"
            register={register}
          />
        </div>

        {/* Interactive Power */}
        <div className="flex items-center gap-3 pl-1">
          <FontAwesomeIcon
            className="h-5 w-5 text-purple-500 dark:text-purple-400"
            fixedWidth
            icon={light("joystick")}
          />
          <Checkbox
            id="interactivePower"
            label="flair.interactivePower"
            name="interactivePower"
            register={register}
          />
        </div>

        {bans.map((ban, index) => (
          <div className="flex items-center gap-3 pl-1" key={ban}>
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon
                className="h-5 w-5 text-purple-500 dark:text-purple-400"
                fixedWidth
                icon={banIcon(ban)}
              />
              <FontAwesomeIcon
                className="h-5 w-5 text-red-500"
                fixedWidth
                icon={light("ban")}
                transform="grow-6"
              />
            </span>
            <Checkbox
              defaultValue={ban}
              id={`banned[${index}]`}
              label={`bans.${ban}`}
              name={`banned[${index}]`}
              register={register}
            />
          </div>
        ))}

        {/* Available From */}
        <Input
          id="availableFrom"
          label="admin.availableFrom"
          name="availableFrom"
          register={register}
          type="date"
        ></Input>

        {/* Version Introduced */}
        <Input
          id="versionIntroduced"
          inputMode="numeric"
          label="admin.versionIntroduced"
          name="versionIntroduced"
          pattern="[0-9]*"
          register={register}
        />

        {/* StarDust/Upgrades Available From */}
        <Input
          id="stardustUpgradesAvailableFrom"
          label="admin.stardustUpgradesAvailableFrom"
          name="stardustUpgradesAvailableFrom"
          register={register}
          type="date"
        ></Input>

        {/* Positioned After */}
        <EmojiSelect
          control={control}
          inputId="position"
          label="admin.positionedAfter"
          name="position"
        />

        {/* Tags */}
        <MultiSelect
          control={control}
          inputId="tags"
          label="tags.title"
          name="tags"
          options={tags?.map((tag) => ({
            label: t(`tags.${tag._id}`),
            value: tag._id,
          }))}
          required
        />

        {/* Worlds */}
        <MultiSelect
          control={control}
          inputId="worlds"
          label="worlds.title"
          name="worlds"
          options={worlds?.map((world) => ({
            label: t(`worlds.${world._id}`),
            value: world._id,
          }))}
        />

        {/* Groups */}
        <div className="flex gap-4">
          <MultiSelect
            className="grow"
            control={control}
            inputId="groups"
            label="groups.title"
            name="groups"
            onChange={() => {
              setValue("numberOfGroups", watch("groups").length);
            }}
            options={groups?.map((group) => ({
              label: t(`groups.${group._id}`),
              value: group._id,
            }))}
          />

          {/* Number of Groups */}
          <Input
            className="hidden sm:flex"
            id="numberOfGroups"
            label="admin.numberOfGroups"
            name="numberOfGroups"
            readOnly
            register={register}
          />
        </div>

        {/* Powers */}
        <section>
          <div className="text-slate-600 dark:text-slate-300">
            {t("powers.title")}{" "}
            <span className="text-red-500 dark:text-red-400">*</span>
          </div>
          <table className="w-full text-slate-600 dark:text-slate-300">
            <thead className="border-b-2 border-b-current">
              <tr>
                <th className="w-full p-2 text-right" colSpan={4}>
                  {t("level")}{" "}
                  <span className="text-sm font-normal text-slate-400">
                    ({t("optional")})
                  </span>
                </th>
                {Array.from(
                  Array(watch("maxLevel") ? +watch("maxLevel") : 1).keys(),
                ).map((level) => (
                  <th className="w-0" key={level} scope="col">
                    {level + 1}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {powers?.map((power) => (
                <tr
                  className="even:bg-slate-200/50 even:dark:bg-slate-800/50"
                  key={power._id}
                >
                  <td className="w-4 rounded-l p-2">
                    <Checkbox
                      checked={checkedPowers.includes(power._id)}
                      id={`powers.${power._id}`}
                      name={`powers.${power._id}`}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setValue(`powers.${power._id}`, []);
                          setCheckedPowers([...checkedPowers, power._id]);
                        } else {
                          setValue(`powers.${power._id}`, undefined);
                          setCheckedPowers(
                            checkedPowers.filter((item) => item !== power._id),
                          );
                        }
                      }}
                    />
                  </td>
                  <td className="w-6 p-2">
                    <div className="w-6">
                      <IKImage
                        alt={t(`powers.${power._id}`)}
                        height="24"
                        loading="lazy"
                        lqip={{ active: true }}
                        path={`${power._id}.png`}
                        transformation={[
                          {
                            di: "error.png",
                            dpr: window.devicePixelRatio.toString(),
                            width: "24",
                          },
                        ]}
                        urlEndpoint={
                          process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT
                        }
                        width="24"
                      />
                    </div>
                  </td>
                  <th className="w-full p-2 text-left font-normal" scope="row">
                    <label htmlFor={`powers.${power._id}`}>
                      {t(`powers.${power._id}`)}
                    </label>
                  </th>
                  <td className="p-2">
                    <button
                      className="flex items-center text-purple-500 disabled:opacity-50 dark:text-purple-400"
                      disabled={!checkedPowers.includes(power._id)}
                      onClick={() => {
                        setValue(
                          `powers.${power._id}`,
                          Array.from(Array(getValues("maxLevel")).keys()).map(
                            (level) => `${level + 1}`,
                          ),
                        );
                      }}
                      type="button"
                    >
                      <FontAwesomeIcon
                        className="h-5 w-5"
                        icon={light("magic-wand-sparkles")}
                      />
                    </button>
                  </td>
                  {Array.from(
                    Array(watch("maxLevel") ? +watch("maxLevel") : 1).keys(),
                  ).map((level) => (
                    <td
                      className={`${
                        level + 1 === +watch("maxLevel") ? "rounded-r" : ""
                      } p-2`}
                      key={level}
                    >
                      <Checkbox
                        disabled={!checkedPowers.includes(power._id)}
                        id={`powers.${power._id}[${level}]`}
                        name={`powers.${power._id}`}
                        register={register}
                        value={level + 1}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        {/* Blitz Modifiers */}
        {checkedPowers.includes("blitzMode") &&
          watch("powers.blitzMode").length > 0 && (
            <MultiSelect
              control={control}
              inputId="blitzModifiers"
              label="blitzModifiers.title"
              name="blitzModifiers"
              options={blitzModifiers?.map((blitzModifier) => ({
                label: t(`blitzModifiers.${blitzModifier._id}`),
                value: blitzModifier._id,
              }))}
              required
            />
          )}

        {/* Recommendations */}
        <section>
          <div className="text-slate-600 dark:text-slate-300">
            {t("recommendations.title")}{" "}
            <span className="text-sm text-slate-400">({t("optional")})</span>
          </div>
          <table className="w-full text-slate-600 dark:text-slate-300">
            <thead className="border-b-2 border-b-current">
              <tr>
                <th className="w-full p-2 text-right" colSpan={3}>
                  {t("level")}
                </th>
                {Array.from(
                  Array(watch("maxLevel") ? +watch("maxLevel") : 1).keys(),
                ).map((level) => (
                  <th className="w-0" key={level} scope="col">
                    {level + 1}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {recommendations?.map((recommendation) => (
                <tr
                  className="even:bg-slate-200/50 even:dark:bg-slate-800/50"
                  key={recommendation._id}
                >
                  <td className="w-4 rounded-l p-2">
                    <Checkbox
                      checked={checkedRecommendations.includes(
                        recommendation._id,
                      )}
                      id={`recommendations.${recommendation._id}`}
                      name={`recommendations.${recommendation._id}`}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setValue(`recommendations.${recommendation._id}`, []);
                          setCheckedRecommendations([
                            ...checkedRecommendations,
                            recommendation._id,
                          ]);
                        } else {
                          setValue(
                            `recommendations.${recommendation._id}`,
                            undefined,
                          );
                          setCheckedRecommendations(
                            checkedRecommendations.filter(
                              (item) => item !== recommendation._id,
                            ),
                          );
                        }
                      }}
                    />
                  </td>
                  <th className="w-full p-2 text-left font-normal" scope="row">
                    <label htmlFor={`recommendations.${recommendation._id}`}>
                      {recommendationLabel(recommendation)}
                    </label>
                  </th>
                  <td className="p-2">
                    <button
                      className="flex items-center text-purple-500 disabled:opacity-50 dark:text-purple-400"
                      disabled={
                        !checkedRecommendations.includes(recommendation._id)
                      }
                      onClick={() => {
                        setValue(
                          `recommendations.${recommendation._id}`,
                          Array.from(Array(getValues("maxLevel")).keys()).map(
                            (level) => `${level + 1}`,
                          ),
                        );
                      }}
                      type="button"
                    >
                      <FontAwesomeIcon
                        className="h-5 w-5"
                        icon={light("magic-wand-sparkles")}
                      />
                    </button>
                  </td>
                  {Array.from(
                    Array(watch("maxLevel") ? +watch("maxLevel") : 1).keys(),
                  ).map((level) => (
                    <td
                      className={`${
                        level + 1 === +watch("maxLevel") ? "rounded-r" : ""
                      } p-2`}
                      key={level}
                    >
                      <Checkbox
                        disabled={
                          !checkedRecommendations.includes(recommendation._id)
                        }
                        id={`recommendations.${recommendation._id}[${level}]`}
                        name={`recommendations.${recommendation._id}`}
                        register={register}
                        value={level + 1}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        {/* Triggers */}
        <section>
          <div className="text-slate-600 dark:text-slate-300">
            <span className="flex gap-4">
              <span>
                {t("triggers.title")}{" "}
                <span className="text-sm text-slate-400">
                  ({t("optional")})
                </span>
              </span>
              <Checkbox
                checked={firstTrigger}
                id="toFirstTrigger"
                label="triggers.different"
                name="toFirstTrigger"
                onChange={(event) => setFirstTrigger(event.target.checked)}
              />
            </span>
          </div>
          <table className="w-full text-slate-600 dark:text-slate-300">
            <thead className="border-b-2 border-b-current">
              <tr>
                <th className="w-full p-2 text-right" colSpan={2}>
                  {t("triggers.level")}
                </th>
                {Array.from(
                  Array(watch("maxLevel") ? +watch("maxLevel") : 1).keys(),
                ).map((level) => (
                  <th className="w-0" key={level} scope="col">
                    {level + 1}
                  </th>
                ))}
                <th className="hidden w-0 p-2 sm:table-cell" scope="col">
                  {t("triggers.average")}
                </th>
              </tr>
            </thead>
            <tbody>
              {triggers?.map(
                (trigger) =>
                  (trigger.key === "toTrigger" ||
                    (trigger.key === "toFirstTrigger" && firstTrigger) ||
                    (trigger.key === "toOvercharge" &&
                      checkedPowers.includes("overchargeEmojis"))) && (
                    <tr
                      className={`${
                        trigger.key === "toOvercharge"
                          ? "text-amber-600 dark:text-amber-400"
                          : ""
                      } ${"even:bg-slate-200/50 even:dark:bg-slate-800/50"}`}
                      key={trigger.key}
                    >
                      <th
                        className="w-full p-2 pr-1 text-left font-normal"
                        dangerouslySetInnerHTML={{
                          __html: t(
                            trigger.key === "toTrigger" && firstTrigger
                              ? `triggers.second`
                              : `triggers.${trigger.value}`,
                          ),
                        }}
                        scope="row"
                      ></th>
                      <td className="p-2">
                        <button
                          className="flex items-center text-purple-500 disabled:opacity-50 dark:text-purple-400"
                          disabled={!watch(`triggers.${trigger.key}[0]`)}
                          onClick={() => {
                            setValue(
                              `triggers.${trigger.key}`,
                              Array.from(
                                Array(getValues("maxLevel")).keys(),
                              ).map(() =>
                                getValues(`triggers.${trigger.key}[0]`),
                              ),
                            );
                            averageTrigger(trigger.key);
                          }}
                          type="button"
                        >
                          <FontAwesomeIcon
                            className="h-5 w-5"
                            icon={light("magic-wand-sparkles")}
                          />
                        </button>
                      </td>
                      {Array.from(
                        Array(
                          watch("maxLevel") ? +watch("maxLevel") : 1,
                        ).keys(),
                      ).map((level) => (
                        <td className="px-1 py-2" key={level}>
                          <Input
                            className="!w-12"
                            id={`triggers.${trigger.key}[${level}]`}
                            inputMode="numeric"
                            name={`triggers.${trigger.key}[${level}]`}
                            onChange={() => {
                              if (trigger.key !== "toFirstTrigger") {
                                averageTrigger(trigger.key);
                              }
                            }}
                            pattern="[0-9]*"
                            register={register}
                          />
                        </td>
                      ))}
                      <td className="hidden px-1 py-2 sm:table-cell">
                        {trigger.key !== "toFirstTrigger" && (
                          <Input
                            className="!w-16"
                            id={`${trigger.key}Average`}
                            name={`${trigger.key}Average`}
                            readOnly
                            register={register}
                          />
                        )}
                      </td>
                    </tr>
                  ),
              )}
            </tbody>
          </table>
        </section>

        <Button
          className="flex w-full gap-2"
          label={`${t("save")} ${t("emoji")}`}
          type="submit"
        >
          <FontAwesomeIcon className="h-5 w-5" icon={light("cloud-arrow-up")} />
          {t("save")} {t("emoji")}
        </Button>

        <Button
          className="flex w-full gap-2"
          // disabled={
          //   emoji?.revision ? +emoji.revision === +latestVersion?.version : true
          // }
          label={`${t("save")} ${t("versions.version")}`}
          onClick={onSaveVersion}
          secondary
        >
          <FontAwesomeIcon className="h-5 w-5" icon={light("clone")} />
          <span>
            {t("save")} {t("versions.version")}{" "}
            <span className="font-normal text-slate-200 dark:text-slate-300">
              (v{latestVersion?.version})
            </span>
          </span>
        </Button>

        {/* Revision */}
        <Input
          hidden
          id="revision"
          name="revision"
          register={register}
          type="number"
        />
      </form>
    </>
  );
};

export default AdminEmoji;
